import { ref, watch, computed } from '@vue/composition-api';
import axios from '@axios';
import useAuth from '@/auth/useAuth';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };
export default function useSurveyList() {
  const refDocumentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'checkbox', label: '', tdClass: "pr-0" },
    { key: 'name', label: i18nT(`Employee`), tdClass: "pl-1" },
    { key: 'type', label: i18nT(`Leave type`), sortable: true, thClass: "sk-header-content" },
    { key: 'from', label: i18nT(`From`), sortable: true },
    { key: 'to', label: i18nT(`To`), sortable: true },
    { key: 'WorkingDays', label: i18nT(`Days`), sortable: true },
    { key: 'status', label: i18nT(`Status`), sortable: true },
    { key: 'createdBy', label: i18nT(`Created by`), thClass: "sk-header-content" },
    {
      key: 'action',
      label: i18nT(`Actions`),
      thClass: 'tbl-actions',
      tdClass: 'sticky-column',
      stickyColumn: true
    },
  ];
  const perPage = ref(10);
  const totalDocuments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const from = ref('');
  const to = ref('');
  const status = ref('');
  const type = ref('');
  const employee = ref('');
  const createdBy = ref('');
  const requests = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value ?
      refDocumentListTable.value.localItems.length :
      0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    };
  });

  const refetchData = () => {
    refDocumentListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      from,
      to,
      status,
      type,
      employee,
      createdBy,
    ],
    () => {
      refetchData();
    }
  );

  const fetchRequests = (ctx, callback) => {
    const curWorkspace = useAuth.getCurWorkspace();

    const params = {
      page: currentPage.value,
      show_per_page: perPage.value,
      sort_by: sortBy.value,
      sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
      search: searchQuery.value
    };

    if (status.value != null) {
      params['filters[status]'] = status.value;
    }

    if (type.value != null) {
      params['filters[absence_type]'] = type.value;
    }
    if (from.value != null) {
      params['filters[start_date]'] = from.value;
    }
    if (to.value != null) {
      params['filters[end_date]'] = to.value;
    }
    if (employee.value != null) {
      params['filters[employee]'] = employee.value;
    }
    if (createdBy.value != null) {
      params['filters[requested_by]'] = createdBy.value;
    }


    axios
      .get(`leaves`, {
        params,
      })
      .then(({ data }) => {
        requests.value = data.data.leaves;
        callback(data.data.leaves);
        totalDocuments.value = data.data.pagination.records_count;
      });
  };

  return {
    fetchRequests,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDocumentListTable,
    totalDocuments,
    from,
    to,
    status,
    type,
    employee,
    createdBy,
    requests,
    refetchData,
  };
}
