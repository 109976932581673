<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`Time off requests`) }}</h1>
                            <b-button
                                variant="primary"
                                v-b-modal.modal-request
                                class="float-right"
                                v-if="iCan(`leave_absence`, `write`)"
                            >
                                <feather-icon icon="PlusCircleIcon"/>
                                {{ i18nT(`Add new`) }}
                            </b-button>
                        </b-col>
                    </b-row>
                    <div class="mb-1">
                        <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
                          {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                          {{ i18nT(`entries`) }}</span>
                    </div>
                    <b-row>

                        <b-col cols="12" md="4">
                            <b-form-group :label="i18nT(`Employee`)">
                                <v-select
                                    v-model="employee"
                                    :options="employeeFilterOptions"
                                    :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="4">
                            <b-form-group :label="i18nT(`From date`)">
                                <date-picker
                                    reset-button
                                    v-model="from"
                                    :placeholder="i18nT(`From date`)"
                                    :max="to"
                                    :start-weekday="1"
                                    :locale="`en-UK`"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                        weekday: 'short',
                                      }"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="4">
                            <b-form-group :label="i18nT(`To date`)">
                                <date-picker
                                    reset-button
                                    v-model="to"
                                    :placeholder="i18nT(`To date`)"
                                    :min="from"
                                    :start-weekday="1"
                                    :locale="`en-UK`"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                        weekday: 'short',
                                      }"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="4">
                            <b-form-group :label="i18nT(`Leave type`)">
                                <v-select
                                    v-model="type"
                                    :options="requestTypeOptions"
                                    :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="4">
                            <b-form-group :label="i18nT(`Status`)">
                                <v-select
                                    v-model="status"
                                    :options="approvalStatusOptions"
                                    :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="4">
                            <b-form-group :label="i18nT(`Created by`)">
                                <v-select
                                    v-model="createdBy"
                                    :options="createdByOptions"
                                    :reduce="(e) => e.value"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <div>
                <div class="row mx-2">
                    <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block"
                        />
                        <b-dropdown
                            text="Actions"
                            size="md"
                            class="d-inline ml-1"
                            variant="outline-secondary"
                            v-if="selectedItems.length > 0 && iCan(`leave_absence`, `write`)"
                        >
                            <b-dropdown-item @click="callApprove">
                                <feather-icon icon="ThumbsUpIcon"/>
                                <span class="align-middle ml-50">
                                    {{ i18nT(`Approve`) }}
                                </span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="bulkReject">
                                <feather-icon icon="ThumbsDownIcon" color="red"/>
                                <span class="align-middle ml-50" style="color: red">
                                    {{i18nT(`Reject`)}}
                                </span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <!-- search input -->
                    <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                        <div class="custom-search d-flex justify-content-end">
                            <b-form-group>
                                <div
                                    role="group"
                                    class="input-group input-group-merge"
                                >
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <feather-icon icon="SearchIcon"/>
                                        </div>
                                    </div>
                                    <b-form-input
                                        v-model.lazy="searchQuery"
                                        :placeholder="i18nT(`Search`)"
                                        type="text"
                                        class="d-inline-block"
                                    />
                                </div>
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <b-table
                    ref="refDocumentListTable"
                    :items="fetchRequests"
                    responsive
                    :fields="computedColumns"
                    primary-key="id"
                    :sort-by.sync="sortBy"
                    show-empty
                    :empty-html="emptyHtml"
                    :sort-desc.sync="isSortDirDesc"
                    class="position-relative"
                    @refreshed="onTableRefresh"
                    v-model="currentItems"
                >
                    <template v-slot:head(checkbox)="data" v-if="iCan(`leave_absence`, `write`)">
                        <span>{{ data.label.toUpperCase() }}</span>
                        <b-form-checkbox
                            class="d-inline-block pl-0"
                            @change="onSelectAll"
                            v-model="selectAllStatus"
                        >
                        </b-form-checkbox>
                    </template>

                    <template #head(action)="data">
                        <span>{{ data.label.toUpperCase() }}</span> 
                        <div class="sk-column-control">
                            <b-dropdown
                                no-caret
                                variant="button"
                                toggle-class="p-0"
                                :right="true"
                                class="pr-1 pl-1 d-flex sk-column-filter"
                                v-if="betaFeature"
                                v-b-tooltip = "i18nT(`Customize columns`)"
                            >
                                <template #button-content>
                                    <feather-icon
                                        icon="SettingsIcon"
                                        size="18"
                                        class="text-right"
                                    />
                                </template>

                                <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                    <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                </div>
                            </b-dropdown>
                        </div>
                    </template>

                    <template #cell(checkbox)="data">
                        <b-form-checkbox
                            v-if="iCan(`leave_absence`, `write`)"
                            v-model="selectedItems"
                            :value="parseInt(data.item.Id)"
                        />
                    </template>

                    <template #cell(name)="data">
                        <div class="media">
                            <div class="media-aside align-self-center">
                              <b-link
                                  v-b-tooltip.hover = "i18nT(`View employee`)"
                                  :to="data.item.NotEmployee !== 1 ? {name: 'view-employee', params: { id: data.item.EmployeeUser.Id }} : null"
                                  class="font-weight-bold d-block text-nowrap"
                              >
                                <b-avatar
                                    :src="data.item.EmployeeUser.ImageUrl"
                                    :text="
                                        (data.item.EmployeeUser.FirstName
                                          ? data.item.EmployeeUser.FirstName.charAt(0)
                                          : '') +
                                        (data.item.EmployeeUser.LastName
                                          ? data.item.EmployeeUser.LastName.charAt(0)
                                          : '')
                                      "
                                    class="mr-50 badge-light-primary"
                                >
                                </b-avatar>
                              </b-link>
                            </div>
                            <div class="media-body">
                                <b-link
                                    @click="onEdit(data)"
                                    class="font-weight-bold d-block text-nowrap"
                                >
                                    {{ data.item.EmployeeUser.Label }}
                                </b-link>
                                <p class="mb-0 text-muted h6 font-small-3">{{ data.item.EmployeeUser.Position }}</p>
                            </div>
                        </div>
                    </template>

                    <template #cell(from)="data">
                        {{ data.item.StartDate | date }}
                    </template>

                    <template #cell(to)="data">
                        {{ data.item.EndDate | date }}
                    </template>

                    <template #cell(type)="data">
                        {{ getAbsenceTypeTitle(data.item.AbsenceType) }}
                    </template>

                    <template #cell(status)="data">
            <span
                :class="statusBadgeClasses(data.item)">
              {{ getStatusTitle(data.item.ApprovalStatus) }}
            </span>
                    </template>

                    <template #cell(createdBy)="data">
                        {{ getCreatedTitle(data.item) }}
                    </template>

                    <template #cell(action)="data">
                        <b-dropdown
                            variant="link"
                            toggle-class="p-0"
                            no-caret
                            v-if="iCan(`leave_absence`, `write`)"
                        >
                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                            </template>
                            <b-dropdown-item
                                v-if="data.item.ApprovalStatus !== 1 && data.item.NotEmployee === 0"
                                @click="approve(data)"
                            >
                                <feather-icon icon="ThumbsUpIcon"/>
                                <span
                                    class="align-middle ml-50"

                                >{{ i18nT(`Approve`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="data.item.ApprovalStatus !== 2 && data.item.NotEmployee === 0"
                                @click="reject(data)"
                            >
                                <feather-icon icon="ThumbsDownIcon"/>
                                <span
                                    class="align-middle ml-50"

                                >{{ i18nT(`Reject`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="data.item.NotEmployee === 0"
                                @click="onEdit(data)"
                            >
                                <feather-icon icon="EditIcon"/>
                                <span
                                    class="align-middle ml-50"
                                >{{ i18nT(`Edit`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="onDelete(data)"
                            >
                                <feather-icon
                                    icon="TrashIcon"
                                    color="red"
                                />
                                <span
                                    class="align-middle ml-50"
                                    style="color: red"

                                >{{ i18nT(`Delete`) }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                                v-if="iCan(`leave_absence`, `write`)"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>
        <!-- MODAL -->
        <modal-leave-request :refetchData="refetchData" :ext-leave="currentLeave" :ext-leave-id="leaveId" @resetForm="resetForm" ></modal-leave-request>
        <b-modal
            id="modal-request-old"
            :title="i18nT(`Time off request`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            no-close-on-backdrop
            @ok="validationRequest"
            @hidden="resetForm"
        >
            <validation-observer ref="requestForm">
                <b-form>
                    <b-row>
                        <b-col sm="12">
                            <b-row>
                                <b-col md="12">
                                    <p>{{ i18nT(`Request time off and select the type of absence.`) }}</p>
                                    <div class="d-flex mb-2">
                                        <div
                                            class="bg-light text-center mr-1 p-1 rounded"
                                            style="width: 100px;"
                                        >
                                            <p class="font-weight-bolder">{{ i18nT(`Available`) }}</p>
                                            <p
                                                class="text-success font-weight-bolder"
                                                style="font-size: 26px;line-height: 10px;"
                                            > {{ availableDays }} </p>
                                            <p class="mb-0">{{ i18nT(`Days`) }}</p>
                                        </div>
                                        <div
                                            class="bg-light text-center mr-1 p-1 rounded"
                                            style="width: 100px;"
                                        >
                                            <p class="font-weight-bolder">{{ i18nT(`Taken`) }}</p>
                                            <p
                                                class="text-primary font-weight-bolder"
                                                style="font-size: 26px;line-height: 10px;"
                                            > {{ takenDays }} </p>
                                            <p class="mb-0">{{ i18nT(`Days`) }}</p>
                                        </div>
                                        <div
                                            class="bg-light text-center p-1 rounded"
                                            style="width: 100px;"
                                        >
                                            <p class="font-weight-bolder">{{ i18nT(`Total`) }}</p>
                                            <p
                                                class="font-weight-bolder"
                                                style="font-size: 26px;line-height: 10px;"
                                            > {{ totalDays }} </p>
                                            <p class="mb-0">{{ i18nT(`Days`) }}</p>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group
                                        :label="i18nT(`Employee`)"
                                        label-for="message"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="To"
                                            rules="required"
                                        >
                                            <v-select
                                                v-model="requestEmployee"
                                                :options="employeeFilterOptions_form"
                                                :state="errors.length > 0 ? false : null"
                                                :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                                label="text"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`From date`)"
                                        label-for="message"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="From"
                                            rules="required"
                                        >
                                            <date-picker
                                                v-model="requestFrom"
                                                :max="requestTo"
                                                placeholder=""
                                                :state="errors.length > 0 ? false : null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label="i18nT(`To date`)"
                                        label-for="message"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="To"
                                            rules="required"
                                        >
                                            <date-picker
                                                v-model="requestTo"
                                                :min="requestFrom"
                                                placeholder=""
                                                :state="errors.length > 0 ? false : null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group
                                        :label="i18nT(`Absence type`)"
                                        label-for="message"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="To"
                                            rules="required"
                                        >
                                            <v-select
                                                v-model="requestType"
                                                :options="requestTypeOptionsForm"
                                                placeholder=""
                                                :state="errors.length > 0 ? false : null"
                                                :reduce="(e) => e.value == '' ? '' : parseInt(e.value)"
                                                label="text"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="12"
                                >
                                    <b-form-group
                                        :label="i18nT(`Note`)"
                                        label-for="Note"
                                    >
                                        <b-form-textarea
                                            :placeholder="i18nT(`Note`)"
                                            v-model="requestDescription"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" md="12">
                                    <p>{{ i18nT(`Attached scans and documents`) }}</p>
                                    <div v-if="currentLeave && currentLeave.AttachedFile">
                                        <b-link :href="currentLeave.AttachedFile.DownloadUrl">
                                            <feather-icon icon="FileIcon" size="19" class="mb-0"/>
                                            {{ currentLeave.AttachedFile.Label }}
                                        </b-link>
                                    </div>
                                </b-col>
                                <b-col
                                    cols="12"
                                    md="12"
                                >
                                    <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        class="mr-1 mb-1 mt-2"
                                        @click="onFilePick"
                                    >
                                        <feather-icon icon="PlusCircleIcon"/>
                                        {{ i18nT(addFileLabel) }}
                                    </b-button>
                                    <b-form-file
                                        v-model="file"
                                        ref="filePicker"
                                        class="hidden"
                                    />
                                    <span v-if="file">
                                        {{ fileName }}
                                        <feather-icon
                                            icon="Trash2Icon"
                                            size="16"
                                            style="margin-right: 8px;"
                                            @click="onFileDelete"
                                        />
                                    </span>
                                    <span v-if="!file">
                                        {{ originalFileName }}
                                    </span>

                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL REJECT REASON -->
        <b-modal
            id="modal-reason"
            :title="i18nT(`Reason`)"
            ok-only
            :ok-title="i18nT(`Ok`)"
            no-close-on-backdrop
            @ok="validateReason"
        >
            <validation-observer ref="reasonForm">
                <b-form>
                    <b-row>
                        <b-col sm="12">
                            <b-row class="mt-2">
                                <b-col md="12">
                                    <b-form-group
                                        :label="i18nT(`Reason`)" class="required"
                                        label-for="message"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Reason"
                                            rules="required"
                                        >
                                            <b-form-textarea
                                                v-model="reason"
                                                :state="errors.length > 0 ? false : null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    // BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BListGroup, BListGroupItem,
    BFormCheckbox,
    BModal,
    BForm,
    BFormFile,
    BFormTextarea,
    BFormInput,
    BLink,
    BAvatar,
    VBTooltip
    // VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useRequestList from './useLeaveRequest'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ModalLeaveRequest from './ModalLeaveRequest.vue'

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import DatePicker from '@core/components/DatePicker.vue'
import useAuth from '@/auth/useAuth'

// import _ from 'lodash'

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        // BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        // BListGroup,
        // BListGroupItem,
        vSelect,
        BFormCheckbox,
        BFormFile,
        BModal,
        BForm,
        BFormTextarea,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BAvatar,
        BLink,
        DatePicker,
        ModalLeaveRequest,
        ToastificationContent
        // VBModal
    },
    directives: {
        Ripple,
       'b-tooltip': VBTooltip
        // 'b-modal': VBModal,
    },
    data() {
        return {
            pagination: {},
            selectedItems: [],
            leaveId: null,
            currentItems: [],
            selectAllStatus: false,
            audienceGroups: [],
            requestTypeOptions: [{value: '', text: this.i18nT(`Any`)}],
            requestTypeOptionsForm: [],
            requestFrom: null,
            requestTo: null,
            requestType: null,
            requestDescription: '',
            requestEmployee: null,
            selectedEmployee: null,
            currentLeave: null,
            approvalStatusOptions: [{value: '', text: this.i18nT(`Any`)}],
            employeeFilterOptions: [{value: '', text: this.i18nT(`All`)}],
            employeeFilterOptions_form: [],
            createdByOptions: [
                {value: '', text: this.i18nT(`Anyone`)},
                {value: 0, text: this.i18nT(`Employee`)},
                {value: 1, text: this.i18nT(`Staff`)}
            ],
            file: null,
            fileName: null,
            originalFileName: null,
            reason: '',
            required,
            availableDays: 0,
            takenDays: 0,
            totalDays: 0,
            showEditForm: 0,
            avalableFields: [
                {label: this.i18nT(`Leave type`), key: 'type', visible: true},
                {label: this.i18nT(`Days`), key: 'WorkingDays', visible: true},
                {label: this.i18nT(`Created by`), key: 'createdBy', visible: true},
            ],
            controlVisible: true,
        }
    },
    computed: {
        addFileLabel() {
            if (this.currentLeave && this.currentLeave.AttachedFile) {
                return this.i18nT('Replace file')
            } else {
                return this.i18nT('Add file')
            }
        },
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your time off requests. No requests have been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2' id='leaveButton' >" + this.i18nT(`Create leave request`) + "</button>"
        },
        computedColumns() {

          let col = [...this.tableColumns]

          for(let i = 0; i < this.avalableFields.length; i++) {
            if(!this.avalableFields[i].visible) {
              col = col.filter(c => c.key !== this.avalableFields[i].key)
            }
          }
          useAuth.setColumnPreferences('timeOff', this.avalableFields);

          return col
      },

    },
    updated(){
        const leaveBtn = document.querySelector('#leaveButton')
        if(leaveBtn) {
            leaveBtn.addEventListener('click', () =>  this.$bvModal.show('modal-request'))
        }
    },
    // beforeUnmount() {
    //   document.querySelector('#leaveButton').removeEventListener('click', this.onShowLeaveModal)
    // },
    watch: {
        file: function (val) {
            if (val) {
                this.file = val
                this.fileName = val.name
            } else {
                this.file = null
                this.fileName = ''
            }
        },
        // requestEmployee: function (val) {
        //     this.getEmployeeLeaveDays(val)
        // },
        requests: function (val) {
            setTimeout(() => {
                const {leaveId} = this.$route.query
                if (leaveId) {
                    const item = val.find(e => (e.Id === parseInt(leaveId)))
                    this.onEdit({item})
                }
            }, 100)
        }
    },
    created() {
        this.getAbsenceType()
        this.getStatus()
        this.getEmployeeFilter()
        this.showEditForm++

        let storePreferences = useAuth.getColumnPreferences('timeOff')
        if(storePreferences) {
          this.avalableFields = storePreferences
        }

        this.fetchRequests(null, this.determineVisibility);

    },
    methods: {
        determineVisibility(invoiceArray) {
            if(invoiceArray.length===0){
              this.controlVisible = false;
            }
        },
        onShowLeaveModal() {
            this.$bvModal.show('modal-request')
        },
        statusBadgeClasses(item) {
            let base_classes = 'badge badge-pill ';
            switch (item.ApprovalStatus) {
                case 0:
                    return base_classes + 'badge-light-warning';
                case 1:
                    return base_classes + 'badge-light-success';
                case 2:
                    return base_classes + 'badge-light-danger';
                default:
                    return base_classes + 'badge-light-secondary';
            }
        },
        getEmployeeLeaveDays(employeeId) {
            if (employeeId)
                this.$http
                    .get(`leaves/leaveDays?employee_id=${employeeId}`)
                    .then(({data}) => {
                        if(data.data.leave_days.length > 0) {
                            data.data.leave_days.map(data => {
                                this.availableDays = data.AvailableDays
                                this.takenDays = data.TakenDays
                                this.totalDays = data.TotalDays
                            })
                        }    
                    })
        },
        getEmployeeFilter() {
            this.$http.get(`leaves/employeesList`).then(({data}) => {
              this.employeeFilterOptions = data.data.map(employee=>({
                value: employee.Id,
                text: employee.Label
              }));
                this.employeeFilterOptions_form = this.employeeFilterOptions.filter(
                    option => option.value
                )
            })
        },
        getStatus() {
            this.$http.get(`leaves/approvalStatusFilter`).then(({data}) => {
                let approvals = dictToSelectArray(data.data)
                this.approvalStatusOptions = [
                    ...this.approvalStatusOptions,
                    ...approvals
                ]
            })
        },
        getAbsenceType() {
            this.$http.get(`hr/absenceTypes`).then(({data}) => {
                let types = data.data.map(item => ({
                    value: item.Id,
                    text: item.Label
                }))
                this.requestTypeOptionsForm = types;
                this.requestTypeOptions = [...this.requestTypeOptions, ...types]
            })
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onDelete(leave) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`leaves?id=${leave.item.Id}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refetchData()
                        })
                        .catch(ex => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onBulkCopy() {
            this.$http
                .post(`surveys/copySurvey?id=${this.selectedItems.join(',')}`)
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`surveys?id=${this.selectedItems.join(',')}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onPutInFolder(data) {
            console.log(data)
            // this.$bvModal.show('modal-pipeline');
        },
        validationRequest(event) {
            event.preventDefault()
            this.$refs.requestForm.validate().then(success => {
                if (success) {
                    //TODO
                    const formData = new FormData()
                    if (this.currentLeave) {
                        formData.append('id', this.currentLeave.Id)
                    }
                    formData.append('AbsenceType', this.requestType)
                    formData.append('StartDate', this.requestFrom)
                    formData.append('EndDate', this.requestTo)
                    formData.append('UserId', this.requestEmployee)
                    formData.append('HalfDay', 0)
                    formData.append('Description', this.requestDescription)
                    formData.append('attached_file', this.file)
                    formData.append('requested_by', useAuth.getProfile().Id)

                    this.$http
                        .post('leaves', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.$bvModal.hide('modal-request')
                            this.requestFrom = null
                            this.requestTo = null
                            this.requestType = null
                            this.requestEmployee = null
                            this.currentLeave = null
                            this.refetchData()
                        })
                        .catch(ex => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        getAbsenceTypeTitle(typeId) {
            try {
                return this.requestTypeOptions.find(option => option.value === typeId)
                    .text
            } catch (ex) {
                console.log(ex)
                return ''
            }
        },
        getStatusTitle(statusId) {
            try {
                return this.approvalStatusOptions.find(
                    option => option.value === statusId + ''
                ).text
            } catch (ex) {
                console.log(ex)
                return ''
            }
        },
        getCreatedTitle(item) {
            //TO DO switch to show user name using "CreatedByUser" property
            if (item.createdBy) {
                return 'Staff'
            } else {
                return item.EmployeeUser ? item.EmployeeUser.Label : 'Employee'
            }
        },
        onFilePick: function () {
            this.$refs['filePicker'].$refs.input.click()
        },
        onFileDelete: function () {
            this.file = null
            this.fileName = ''
        },
        approve(data) {

            this.selectedEmployee = data.item.Id
            this.callApprove()
        },
        callApprove() {
            const formData = new FormData()
            formData.append('id', this.selectedEmployee ? this.selectedEmployee : this.selectedItems.join(','))
            this.$http
                .post('leaves/approve', formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.selectedEmployee = null
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
                .catch(ex => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(ex),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        bulkReject() {
            this.$bvModal.show('modal-reason')
        },
        reject(data) {

            this.selectedEmployee = data.item.Id
            this.$bvModal.show('modal-reason')
        },
        validateReason(event) {
            event.preventDefault()
            this.$refs.reasonForm.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    formData.append('id', this.selectedEmployee ? this.selectedEmployee : this.selectedItems.join(','))
                    formData.append('reason', this.reason)
                    this.$http
                        .post('leaves/reject', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.$bvModal.hide('modal-reason')
                            this.selectedEmployee = null
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                        .catch(ex => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onEdit(data) {
            this.leaveId = data.item.Id;
            // this.$http
            //     .get(`leaves/leaveDays?employee_id=${data.item.UserId}`)
            //     .then(({data}) => {
            //         this.availableDays = data.data.available
            //         this.takenDays = data.data.taken
            //         this.totalDays = data.data.total
            //     })
            //     .catch(ex => {
            //         this.$toast({
            //             component: ToastificationContent,
            //             position: 'top-right',
            //             props: {
            //                 title: this.errorMessage(ex),
            //                 icon: 'AlertTriangleIcon',
            //                 variant: 'danger'
            //             }
            //         })
            //     })
            this.currentLeave = data.item
            this.requestFrom = data.item.StartDate
            this.requestTo = data.item.EndDate
            this.requestDescription = data.item.Description
            this.requestType = data.item.AbsenceType
            this.requestEmployee = data.item.UserId
            this.$bvModal.show('modal-request')
        },
        resetForm() {
            this.availableDays = 0
            this.takenDays = 0
            this.totalDays = 0
            this.currentLeave = {}
            this.requestDescription = ''
            this.file = null
            this.fileName = null
            this.originalFileName = null
            this.requestFrom = ''
            this.requestTo = ''
            this.requestType = ''
            this.requestEmployee = null
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    setup() {
        const {
            fetchRequests,
            requests,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            from,
            to,
            type,
            employee,
            createdBy,
            status,
            surveys,
            refetchData
        } = useRequestList()

        return {
            fetchRequests,
            requests,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            from,
            to,
            type,
            employee,
            createdBy,
            status,
            avatarText,
            surveys,
            refetchData
        }
    }
}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}

.cv-card {
    min-height: 180px;

    span {
        font-size: 12px;
    }
}
</style>
